// import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
// import "/styles/header.css"

// const Header = ({ props }) => {

//   const navRef = React.useRef()

//   const [isNavExpanded, setIsNavExpanded] = React.useState(false)
//   const [isHeaderFixed, setIsHeaderFixed] = React.useState(0)

//   React.useEffect(() => {

//     const handleScroll = () => {
//       const scrollPosition = window.scrollY
//       const scrollThreshold = 100

//       if (scrollPosition > scrollThreshold) {
//         setIsHeaderFixed(true)
//       } else {
//         setIsHeaderFixed(false)
//       }

//     }
//     window.addEventListener("scroll", handleScroll)

//     return () => {
//       window.removeEventListener("scroll", handleScroll)
//     }
//   }, [])

//   React.useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (navRef.current && !navRef.current.contains(event.target)) {
//         setIsNavExpanded(false)
//       }
//     }
//     document.addEventListener('mousedown', handleClickOutside)
//     return () => {
//       document.removeEventListener('dragentermousedown', handleClickOutside)
//     }
//   }, [navRef])

//   const items = [
//     {
//       label: "Home",
//       icon: 'pi pi-home',
//       link: "/",
//       matchPath: "/",
//     },
//     {
//       label: "About Us",
//       icon: 'pi pi-star',
//       link: "/about",
//       matchPath: "/about/",
//     },
//     {
//       label: 'Services',
//       icon: 'pi pi-search',
//       link: "",
//       matchPath: "/services/",
//       items: [
//         {
//           label: "Strategic Consulting",
//           icon: 'pi pi-bolt',
//           link: "/services/strategic-consulting"
//         },
//         {
//           label: "Operational Excellence",
//           icon: 'pi pi-server',
//           link: "/services/operational-excellence"
//         },
//         {
//           label: "Migration & Modernization",
//           icon: 'pi pi-android',
//           link: "/services/migration-modernization"
//         },
//         {
//           label: "AI & Automation Integration",
//           icon: 'pi pi-palette',
//           link: "/services/automation-integration"
//         }
//       ],
//     },
//     {
//       label: 'Solutions',
//       icon: 'pi pi-search',
//       link: "",
//       matchPath: "/solutions/",
//       items: [
//         {
//           label: "Industry-Specific Solutions",
//           icon: 'pi pi-building',
//           link: "/solutions/industry-specific",
//         },
//         {
//           label: "Digital Workplace Transformation",
//           icon: 'pi pi-server',
//           link: "/solutions/digital-workplace"
//         },
//         {
//           label: "Data-Driven Business Model",
//           icon: 'pi pi-database',
//           link: "/solutions/data-drive"
//         },
//         {
//           label: "Cloud Scalability Solutions",
//           icon: 'pi pi-cloud-upload',
//           link: "/solutions/cloud-scalability"
//         }
//       ],
//     },
//     {
//       label: 'Technologies & Platforms',
//       icon: 'pi pi-sliders-h',
//       link: "",
//       matchPath: "/technologies/",
//       items: [
//         {
//           label: "Microsoft Ecosystem",
//           icon: 'pi pi-microsoft',
//           link: "/technologies/microsoft-ecosystem"
//         },
//         {
//           label: "Low Code Innovation",
//           icon: 'pi pi-server',
//           link: "/technologies/low-code-innovation"
//         },
//         {
//           label: "Azure Cloud Services",
//           icon: 'pi pi-cloud-upload',
//           link: "/technologies/azure-cloud-services"
//         },
//         {
//           label: "Data Intelligence & AI",
//           icon: 'pi pi-database',
//           link: "/technologies/data-intelligence"
//         },
//         {
//           label: "Modern Development Frameworks",
//           icon: 'pi pi-code',
//           link: "/technologies/modern-development"
//         },
//         {
//           label: "Advanced UI/UX Tools",
//           icon: 'pi pi-box',
//           link: "/technologies/advanced-tools"
//         },
//       ],
//     },
//   ]

//   return (
// <div className={`header-main ${isHeaderFixed ? "header-scroll" : ""}`}>
//   <div className='container'>
//     <div ref={navRef} className='d-flex justify-content-between py-3'>
//       <div className='mt-2 common-z-index'>
//         <Link to="/">
//           <StaticImage placeholder="blurred" src="/images/logo.png" height={55} width={140} alt="logo" />
//         </Link>
//       </div>
//       <button className="navbar-toggler common-z-index" onClick={() => setIsNavExpanded(!isNavExpanded)}>
//         <i className="pi pi-list"></i>
//       </button>
//       <nav className={`menu common-z-index ${isNavExpanded ? "expanded" : ""}`}>
//         <ol>
//           {items.map((val, ind) => {
//             if (val.items === undefined) {
//               return (
//                 <li key={ind} className={`menu-item ${props.path === val.matchPath ? "active" : ""}`}>
//                   <Link onClick={() => setIsNavExpanded(false)} to={val.link}>
//                     {val.label}
//                   </Link>
//                 </li>
//               )
//             } else {
//               return (
//                 <li key={ind} className={`menu-item ${ind === 4 && "last-child"} ${props.path?.includes(val.matchPath) ? "active2" : ""}`}>
//                   <Link to={val.link}>
//                     {val.label}
//                   </Link>
//                   <ol className="sub-menu">
//                     {val.items?.map((item, index) => {
//                       return (
//                         <li key={index} className="menu-item">
//                           <Link onClick={() => setIsNavExpanded(false)} to={item?.link}>
//                             {item?.label}
//                           </Link>
//                         </li>
//                       )
//                     })}
//                   </ol>
//                 </li>
//               )
//             }
//           })}
//         </ol>
//       </nav>
//     </div>
//   </div>
// </div>
//   )
// }

// export default Header

import React, { useEffect, useState } from "react"
import "../../styles/header.css"
// import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import logowhite from "../../images/logo-svg-w.svg"
import logoblack from "../../images/logo-svg.svg"
import StrategicConsulting from "../../images/header/strategic-consulting.svg"
import OperationalExcellance from "../../images/header/operational-excellance.svg"
import MigrationModernization from "../../images/header/migration-modernization.svg"
import AIAutomationIntegration from "../../images/header/ai-automation-integration.svg"
import DigitalEvolutionStratrgy from "../../images/header/digital-evolution-strategy.svg"
import TechAdvisory from "../../images/header/tech-advisory.svg"
import DataInnovationConsulting from "../../images/header/data-innovation-consulting.svg"
import NextGenCloudSolutions from "../../images/header/next-gen-cloud-solution.svg"
import VirtualSupportEcosystem from "../../images/header/virtual-support-ecosystem.svg"
import EnterpriseApplicationServices from "../../images/header/enterprise-application-services.svg"
import CloudInfrastructireOptimization from "../../images/header/cloud-infrastructure-optimization.svg"
import LegacyToCloudTransition from "../../images/header/legacy-to-cloud-transition.svg"
import EnterpriseSystemUpgrades from "../../images/header/enterprise-system-upgrades.svg"
import CommunicationSystemOverhaul from "../../images/header/communication-system-overhaul.svg"
import AlPoweredTransformations from "../../images/header/ai-powered-transformations.svg"
import IntelligentProcessAutomation from "../../images/header/intelligent-process-automation.svg"
import CognitiveComputingServices from "../../images/header/cognitive-computing-services.svg"
import MicrosoftCopilot from "../../images/header/cognitive-computing-services.svg"
import MicrosoftEcosystem from "../../images/header/microsoft-ecosystem.svg"
import LowCodeInnovation from "../../images/header/low-code-innovation.svg"
import AzureCloudService from "../../images/header/azure-cloud-service.svg"
import DataIntelligenceAl from "../../images/header/data-intelligence-and-ai.svg"
import ModernDevelopmentFrameworks from "../../images/header/modern-development-framework.svg"
import AdvancedUIUXTools from "../../images/header/advanced-ui-ux-tools.svg"
import SharePoint from "../../images/header/sharepoint.svg"
import Office365 from "../../images/header/office-365.svg"
import Dynamic365 from "../../images/header/dyanamic-365.svg"
import Teams from "../../images/header/teams.svg"
import PowerApps from "../../images/header/power-apps.svg"
import PowerAutomate from "../../images/header/power-automate.svg"
import OutSystems from "../../images/header/outsystems.svg"
import Nintex from "../../images/header/nintex-header.svg"
import AzureApplications from "../../images/header/azure-applications.svg"
import AzureDataServices from "../../images/header/azure-data-services.svg"
import AzureCloudInfraServices from "../../images/header/azure-cloud-infra-services.svg"
import AzureAIAndCognitiveServices from "../../images/header/azure-ai-and-congitive-services.svg"
import CloudMigrations from "../../images/header/cloud-migration.svg"
import AzureCognitiveServices from "../../images/header/azure-cognative-services.svg"
import PowerBI from "../../images/header/power-bi.svg"
import AzureDataFactory from "../../images/header/azure-data-factory.svg"
import AIModelDevelopment from "../../images/header/ai-model-development.svg"
import AzureSynapseAnalyticsServices from "../../images/header/azure-synapse-analytics-services-header.svg"
import ReactJs from "../../images/header/react.svg"
import NodeJs from "../../images/header/node.svg"
import PowerQuery from "../../images/header/powerquery.svg"
import SPFX from "../../images/header/spfx-header.svg"
import PowerFx from "../../images/header/powerfx.svg"
import NextJs from "../../images/header/nextjs.svg"
import NetCore from "../../images/header/net-core.svg"
import GraphAPI from "../../images/header/graph-api.svg"
import GatsByJs from "../../images/header/gatsbyjs.svg"
import AngularJs from "../../images/header/angular.svg"
import Figma from "../../images/header/figma.svg"
import AdobeXD from "../../images/header/adobeXD.svg"
import IndustrySpecificSolutions from "../../images/header/industry-specific-solutions.svg"
import DataDrivenBusinessModel from "../../images/header/data-driven-business-model.svg"
import DigitalWorkplaceTransformation from "../../images/header/digital-workplace-transformation.svg"
import CloudScalabilitySolutions from "../../images/header/cloud-scalability-solutions.svg"
import About from "../../images/header/about.svg"
import Career from "../../images/header/career.svg"
import Blogs from "../../images/header/blogs.svg"
import Contact from "../../images/header/contact.svg"

const Header = () => {

  const [isHeaderFixed, setIsHeaderFixed] = useState(0);
  const [isActive, setIsActive] = useState(0);
  const [isServicesContentVisible, setIsServicesContentVisible] = useState(0);
  const [isTechnologyContentVisible, setIsTechnologyContentVisible] = useState(0);

  const location = window.location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const scrollThreshold = 100;

      if (scrollPosition > scrollThreshold) {
        setIsHeaderFixed(true)
      } else {
        setIsHeaderFixed(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleClick = (number) => {
    setIsActive(prevState => (prevState === number ? 0 : number));
  }

  const handleOptionsClick = (number) => {
    setIsActive(prevState => (prevState === number ? 1 : number));
  }

  const handleServicesClick = (number) => {
    setIsActive(prevState => (prevState === number ? 2 : number));
  }

  const handleTechnologyClick = (number) => {
    setIsActive(prevState => (prevState === number ? 3 : number))
  }

  const handleServicesMouseOver = (number) => {
    setIsServicesContentVisible(number);
  }

  const handleTechnologyMouseOver = (number) => {
    setIsTechnologyContentVisible(number);
  }

  return (
    <>
      <header className={`main-header ${isHeaderFixed ? "header-scroll" : ""} ${location === '/' || location === '/services/' || location === '/solutions/' || location === '/technologies/' ? "home-header" : ""} ${location.startsWith("/auth/admin") || location.startsWith("/auth/employee") || location.startsWith('/employee') || location.startsWith('/admin') ? 'd-none' : ''}`}>
        <div className="header-wrapper">
          <div className="container header-padding">
            <div className="hide-tab">
              <Link to="/">
                {/* <StaticImage placeholder="blurred" src="../../images/logo.png" height={55} width={140} alt="logo" /> */}
                {isHeaderFixed && location === '/' ? (
                  <img className="logo-new" src={logoblack} alt="logo" />
                ) : location === '/' || location === '/services/' || location === '/solutions/' || location === '/technologies/' ? (
                  <img className="logo-new" src={logowhite} alt="logo" />
                ) : (
                  <img className="logo-new" src={logoblack} alt="logo" />
                )}

              </Link>
              <nav>
                <ul>
                  <li id="homePage">
                    <div className="menu-title"><Link to="/">Home</Link></div>
                    {/* <div id="servicesSubMenu" className="dropdown-submenu ">
                      <div className="container">
                        <ul className="main-sub-menu">
                          <li>
                            <a href="/services/web-development">
                              <img loading="lazy" src="https://img.webcodegenie.com/img/header/web-development.svg?ver=20240318052029" alt="Web Development" height="36" width="36" />
                              <div>
                                <h6>Web Development</h6>
                                <p>Build your digital presence with our top-notch web development services</p>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="/services/mobile-app-development">
                              <img loading="lazy" src="https://img.webcodegenie.com/img/header/mobile-development.svg?ver=20240318052029" alt="Mobile App Development" height="36" width="36" />
                              <div>
                                <h6>Mobile App Development</h6>
                                <p>Unlock the power of mobile with our expert app development services - your ideas, our innovation</p>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="/services/web-design">
                              <img loading="lazy" src="https://img.webcodegenie.com/img/header/ui-ux.svg?ver=20240318052029" alt="Web Design" height="36" width="36" />
                              <div>
                                <h6>Web Design</h6>
                                <p>Transform your digital vision into a stunning reality with our premium web design services</p>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="/services/software-testing">
                              <img loading="lazy" src="https://img.webcodegenie.com/img/header/software-testing.svg?ver=20240318052029" alt="Dev Ops" height="36" width="36" />
                              <div>
                                <h6>Software Testing</h6>
                                <p>Deliver flawless software with our top-rated testing services - quality you can rely on</p>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="/services/digital-marketing">
                              <img loading="lazy" src="https://img.webcodegenie.com/img/header/digital-marketing.svg?ver=20240318052029" alt="Digital Marketing" height="36" width="36" />
                              <div>
                                <h6>Digital Marketing</h6>
                                <p>Boost your online presence and skyrocket your sales with our dynamic digital marketing services</p>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="/services/iot-development">
                              <img loading="lazy" src="https://img.webcodegenie.com/img/header/iot.svg?ver=20240318052029" alt="IOT" height="36" width="36" />
                              <div>
                                <h6>IOT</h6>
                                <p>Experience the power of connected devices with our cutting-edge IoT services</p>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="/services/big-data">
                              <img loading="lazy" src="https://img.webcodegenie.com/img/header/big-data.svg?ver=20240318052029" alt="Big Data" height="36" width="36" />
                              <div>
                                <h6>Big Data</h6>
                                <p>Unleash the potential of your data with our big data services - turning information into insights</p>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="/services/cloud-computing">
                              <img loading="lazy" src="https://img.webcodegenie.com/img/header/cloud-computing.svg?ver=20240318052029" alt="Cloud Computing" height="36" width="36" />
                              <div>
                                <h6>Cloud Computing</h6>
                                <p>Transform your business with our cloud computing services - scalable, secure, and always available</p>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </li>
                  <li id="servicesList">
                    <Link to="/services" className="menu-title">Services</Link>
                    <div id="techSubMenu" className="services dropdown-submenu ">
                      {/* <div className="container"> */}
                      <div className="tech-grid">
                        <div className="custom-hover-nav">
                          <div className="hover-tab">
                            <div className="tab">
                              <Link to="/services/strategic-consulting">
                                <div
                                  role="button"
                                  tabIndex={0}
                                  data-tab="tab-1"
                                  className={`tablinks ${isServicesContentVisible === 0 ? `active` : ``}`}
                                  onMouseOver={() => handleServicesMouseOver(0)}
                                >
                                  <img className="app-icon" loading="lazy" src={StrategicConsulting} alt="Strategic Consulting" height="32" width="32" />
                                  Strategic Consulting
                                </div>
                              </Link>
                              <Link to="/services/operational-excellence">
                                <div data-tab="tab-2" className={`tablinks ${isServicesContentVisible === 1 ? `active` : ``}`} onMouseOver={() => handleServicesMouseOver(1)}>
                                  <img className="app-icon" loading="lazy" src={OperationalExcellance} alt="Operational Excellence" height="32" width="32" />
                                  Operational Excellence
                                </div>
                              </Link>
                              <Link to="/services/migration-modernization">
                                <div data-tab="tab-3" className={`tablinks ${isServicesContentVisible === 2 ? `active` : ``}`} onMouseOver={() => handleServicesMouseOver(2)}>
                                  <img className="app-icon" loading="lazy" src={MigrationModernization} alt="Migration & Modernization" height="32" width="32" />
                                  Migration & Modernization
                                </div>
                              </Link>
                              <Link to="/services/automation-integration">
                                <div data-tab="tab-4" className={`tablinks ${isServicesContentVisible === 3 ? `active` : ``}`} onMouseOver={() => handleServicesMouseOver(3)}>
                                  <img className="app-icon" loading="lazy" src={AIAutomationIntegration} alt="AI & Automation Integration" height="32" width="32" />
                                  AI & Automation Integration
                                </div>
                              </Link>
                            </div>
                            <div className="tabcontent-wrapper">
                              <div id="tab-1" className={`tabcontent ${isServicesContentVisible === 0 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/services/strategic-consulting/digital-evolution-strategy"><img src={DigitalEvolutionStratrgy} alt="Digital Evolution Strategy" />Digital Evolution Strategy</Link></li>
                                  <li><Link to="/services/strategic-consulting/tech-advisory"><img src={TechAdvisory} alt="Tech Advisory" />Tech Advisory</Link></li>
                                  <li><Link to="/services/strategic-consulting/data-innovation-consulting"><img src={DataInnovationConsulting} alt="Data Innovation Consulting" />Data Innovation Consulting</Link></li>
                                  <li><Link to="/services/strategic-consulting/next-gen-cloud-solutions"><img src={NextGenCloudSolutions} alt="Next-Gen Cloud Solutions" />Next-Gen Cloud Solutions</Link></li>
                                  {/* <li><Link to="/services/strategic-consulting/insight-driven-analytics"><img src="https://cdn-icons-png.flaticon.com/512/10179/10179168.png" alt="Insight-Driven Analytics" />Insight-Driven Analytics</Link></li> */}
                                </ul>
                              </div>
                              <div id="tab-2" className={`tabcontent ${isServicesContentVisible === 1 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/services/operational-excellence/enterprise-application-services"><img src={EnterpriseApplicationServices} alt="Enterprise Application Services" />Enterprise Application Services</Link></li>
                                  <li><Link to="/services/operational-excellence/cloud-infrastructure-optimization"><img src={CloudInfrastructireOptimization} alt="Cloud Infrastructure Optimization" />Cloud Infrastructure Optimization</Link></li>
                                  <li><Link to="/services/operational-excellence/virtual-support-ecosystem"><img src={VirtualSupportEcosystem} alt="Virtual Support Ecosystem" />Virtual Support Ecosystem</Link></li>
                                </ul>
                              </div>
                              <div id="tab-3" className={`tabcontent ${isServicesContentVisible === 2 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/services/migration-modernization/legacy-to-cloud-transition"><img src={LegacyToCloudTransition} alt="Legacy to Cloud Transition" />Legacy to Cloud Transition</Link></li>
                                  <li><Link to="/services/migration-modernization/enterprise-system-upgrades"><img src={EnterpriseSystemUpgrades} alt="Enterprise System Upgrades" />Enterprise System Upgrades</Link></li>
                                  <li><Link to="/services/migration-modernization/communication-system-overhaul"><img src={CommunicationSystemOverhaul} alt="Communication System Overhaul" />Communication System Overhaul</Link></li>
                                </ul>
                              </div>
                              <div id="tab-4" className={`tabcontent ${isServicesContentVisible === 3 ? `active` : ``}`}>
                                <ul>
                                  <li> <Link to="/services/automation-integration/ai-powered-transformations"><img src={AlPoweredTransformations} alt="AI-Powered Transformations" />AI-Powered Transformations</Link></li>
                                  <li> <Link to="/services/automation-integration/intelligent-process-automation"><img src={IntelligentProcessAutomation} alt="Intelligent Process Automation" />Intelligent Process Automation</Link></li>
                                  <li> <Link to="/services/automation-integration/cognitive-computing-services"><img src={CognitiveComputingServices} alt="Cognitive Computing Services" />Cognitive Computing Services</Link></li>
                                  <li> <Link to="/services/automation-integration/microsoft-copilot"><img src={MicrosoftCopilot} alt="Microsoft Copilot" />Microsoft Copilot</Link></li>
                                </ul>
                              </div>
                              <div className="border-between"></div>
                              <div className="bottom-box">
                                {/* <div className="bottom-box-thumb">
                                  <img src="https://img.webcodegenie.com/img/technology/technology-bottom.svg?ver=20240318052029" alt="Let's Chat" />
                                </div> */}
                                <div className="bottom-box-content">
                                  <h3>Finding a Tech Partner?</h3>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>We offer tech assistance to small, medium, and enterprise-sized businesses.</p>
                                    <Link to="/contact" className="button-holder">
                                      <button className="button button-2 hover-slide-right">
                                        <span>Let's Chat</span>
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </li>
                  <li id="technologyList">
                    <Link to="/technologies" className="menu-title">Technologies & Platforms</Link>
                    <div id="techSubMenu" className="new dropdown-submenu">
                      {/* <div className="container"> */}
                      <div className="tech-grid">
                        <div className="custom-hover-nav">
                          <div className="hover-tab">
                            <div className="tab">
                              <Link to="/technologies/microsoft-ecosystem">
                                <div data-tab="tab-1" className={`tablinks ${isTechnologyContentVisible === 0 ? `active` : ``}`} onMouseOver={() => handleTechnologyMouseOver(0)}>
                                  <img className="app-icon" loading="lazy" src={MicrosoftEcosystem} alt="Microsoft Ecosystem" height="32" width="32" />
                                  Microsoft Ecosystem
                                </div>
                              </Link>
                              <Link to="/technologies/low-code-innovation">
                                <div data-tab="tab-2" className={`tablinks ${isTechnologyContentVisible === 1 ? `active` : ``}`} onMouseOver={() => handleTechnologyMouseOver(1)}>
                                  <img className="app-icon" loading="lazy" src={LowCodeInnovation} alt="Low Code Innovation" height="32" width="32" />
                                  Low Code Innovation
                                </div>
                              </Link>
                              <Link to="/technologies/azure-cloud-services">
                                <div data-tab="tab-3" className={`tablinks ${isTechnologyContentVisible === 2 ? `active` : ``}`} onMouseOver={() => handleTechnologyMouseOver(2)}>
                                  <img className="app-icon" loading="lazy" src={AzureCloudService} alt="Azure Cloud service" height="32" width="32" />
                                  Azure Cloud service
                                </div>
                              </Link>
                              <Link to="/technologies/data-intelligence">
                                <div data-tab="tab-4" className={`tablinks ${isTechnologyContentVisible === 3 ? `active` : ``}`} onMouseOver={() => handleTechnologyMouseOver(3)}>
                                  <img className="app-icon" loading="lazy" src={DataIntelligenceAl} alt="Data Intelligence & AI" height="32" width="32" />
                                  Data Intelligence & AI
                                </div>
                              </Link>
                              <Link to="/technologies/modern-development">
                                <div data-tab="tab-4" className={`tablinks ${isTechnologyContentVisible === 4 ? `active` : ``}`} onMouseOver={() => handleTechnologyMouseOver(4)}>
                                  <img className="app-icon" loading="lazy" src={ModernDevelopmentFrameworks} alt="Modern Development Frameworks" height="32" width="32" />
                                  Modern Development Frameworks
                                </div>
                              </Link>
                              {/* <Link to="/technologies/advanced-tools">
                                <div data-tab="tab-4" className={`tablinks ${isTechnologyContentVisible === 5 ? `active` : ``}`} onMouseOver={() => handleTechnologyMouseOver(5)}>
                                  <img className="app-icon" loading="lazy" src={AdvancedUIUXTools} alt="Advanced UI/UX Tools" height="32" width="32" />
                                  Advanced UI/UX Tools
                                </div>
                              </Link> */}
                            </div>
                            <div className="tabcontent-wrapper">
                              <div id="tab-1" className={`tabcontent ${isTechnologyContentVisible === 0 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/technologies/microsoft-ecosystem/sharepoint"><img src={SharePoint} alt="SharePoint" />SharePoint</Link></li>
                                  <li><Link to="/technologies/microsoft-ecosystem/office-365"><img src={Office365} alt="Office 365" />Office 365</Link></li>
                                  <li><Link to="/technologies/microsoft-ecosystem/dynamics-365"><img src={Dynamic365} alt="Dynamics 365" />Dynamics 365</Link></li>
                                  <li><Link to="/technologies/microsoft-ecosystem/teams"><img src={Teams} alt="Teams" />Teams</Link></li>
                                </ul>
                              </div>
                              <div id="tab-2" className={`tabcontent ${isTechnologyContentVisible === 1 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/technologies/low-code-innovation/powerapps"><img src={PowerApps} alt="PowerApps" />PowerApps</Link></li>
                                  <li><Link to="/technologies/low-code-innovation/power-automate"><img src={PowerAutomate} alt="Power Automate" />Power Automate</Link></li>
                                  <li><Link to="/technologies/low-code-innovation/outsystems"><img src={OutSystems} alt="OutSystems" />OutSystems</Link></li>
                                  <li><Link to="/technologies/low-code-innovation/nintex"><img src={Nintex} alt="Nintex" />Nintex</Link></li>
                                </ul>
                              </div>
                              <div id="tab-3" className={`tabcontent ${isTechnologyContentVisible === 2 ? `active` : ``}`}>
                                <ul>
                                  <li> <Link to="/technologies/azure-cloud-services/azure-applications"><img src={AzureApplications} alt="Azure Applications" />Azure Applications </Link></li>
                                  <li> <Link to="/technologies/azure-cloud-services/cloud-migrations"><img src={CloudMigrations} alt=" Cloud Migrations" /> Cloud Migrations</Link></li>
                                  <li> <Link to="/technologies/azure-cloud-services/azure-data-services"><img src={AzureDataServices} alt="Azure Data Services" />Azure Data Services</Link></li>
                                  <li> <Link to="/technologies/azure-cloud-services/azure-cloud-infra-services"><img src={AzureCloudInfraServices} alt="Azure Cloud Infra Services" />Azure Cloud Infra Services</Link></li>
                                  <li> <Link to="/technologies/azure-cloud-services/azure-ai-and-cognitive-services"><img src={AzureAIAndCognitiveServices} alt="Azure AI and Cognitive Services" />Azure AI and Cognitive Services</Link></li>
                                </ul>
                              </div>
                              <div id="tab-4" className={`tabcontent ${isTechnologyContentVisible === 3 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/technologies/data-intelligence/power-bi"><img src={PowerBI} alt="Power BI" />Power BI</Link></li>
                                  <li><Link to="/technologies/data-intelligence/azure-data-factory"><img src={AzureDataFactory} alt="Azure Data Factory" />Azure Data Factory</Link></li>
                                  <li><Link to="/technologies/data-intelligence/ai-model-development"><img src={AIModelDevelopment} alt="AI model development" />AI model development</Link></li>
                                  <li><Link to="/technologies/data-intelligence/azure-synapse-analytics-services"><img src={AzureSynapseAnalyticsServices} alt="Azure Synapse Analytics Services" />Azure Synapse Analytics Services</Link></li>
                                </ul>
                              </div>
                              <div id="tab-5" className={`tabcontent ${isTechnologyContentVisible === 4 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/technologies/modern-development/react-js"><img src={ReactJs} alt=" React JS" />React JS</Link></li>
                                  {/* <li><Link to="/technologies/modern-development/angular-js"><img src={ReactJs} alt="Angular JS" />Angular JS</Link></li> */}
                                  <li><Link to="/technologies/modern-development/spfx"><img src={SPFX} alt=" SPFX" />SPFX</Link></li>
                                  <li><Link to="/technologies/modern-development/powerquery"><img src={PowerQuery} alt=" PowerQuery" />PowerQuery</Link></li>
                                  <li><Link to="/technologies/modern-development/powerfx"><img src={PowerFx} alt=" PowerFx" />PowerFx</Link></li>
                                  {/* <li><Link to="/technologies/modern-development/angular-js"><img src={AngularJs} alt="Angular JS" />Angular JS</Link></li> */}
                                  <li><Link to="/technologies/modern-development/node-js"><img src={NodeJs} alt="Node.js" />Node.js</Link></li>
                                  <li><Link to="/technologies/modern-development/next-js"><img src={NextJs} alt="Next.js" />Next.js</Link></li>
                                  <li><Link to="/technologies/modern-development/net-core"><img src={NetCore} alt=".Net Core" />.Net Core</Link></li>
                                  <li><Link to="/technologies/modern-development/gatsby"><img src={GatsByJs} alt="Gatsby" />Gatsby</Link></li>
                                  <li><Link to="/technologies/modern-development/graph-api"><img src={GraphAPI} alt="Microsoft Graph API" />Microsoft Graph API</Link></li>
                                </ul>
                              </div>
                              {/* <div id="tab-6" className={`tabcontent ${isTechnologyContentVisible === 5 ? `active` : ``}`}>
                                <ul>
                                  <li><Link to="/technologies/advanced-tools/figma"><img src={Figma} alt="Figma" />Figma</Link></li>
                                  <li><Link to="/technologies/advanced-tools/adobe-xd"><img src={AdobeXD} alt="Adobe XD" />Adobe XD</Link></li>
                                </ul>
                              </div> */}
                              <div className="border-between"></div>
                              <div className="bottom-box">
                                {/* <div className="bottom-box-thumb">
                                  <img src="https://img.webcodegenie.com/img/technology/technology-bottom.svg?ver=20240318052029" alt="Let's Chat" />
                                </div> */}
                                <div className="bottom-box-content">
                                  <h3>Finding a Tech Partner?</h3>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>We offer tech assistance to small, medium, and enterprise-sized businesses.</p>
                                    <Link to="/contact" className="button-holder">
                                      <button className="button button-2 hover-slide-right">
                                        <span>Let's Chat</span>
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </li>
                  {/* <li id="hireList">
                    <div className="menu-title">Hire Developers</div>
                    <div id="hireSubMenu" className="dropdown-submenu ">
                      <div className="container">
                        <div className="tech-grid">
                          <div className="custom-hover-nav">
                            <div className="hover-tab">
                              <div className="tab">
                                <div data-tab="tab-6" className="tablinks active">
                                  <img className="app-icon" loading="lazy" src="https://img.webcodegenie.com/img/header/hire/mobile.svg?ver=20240318052029" alt="Mobile App" height="32" width="32" />
                                  Hire Mobile App Developers
                                </div>
                                <div data-tab="tab-7" className="tablinks">
                                  <img className="app-icon" loading="lazy" src="https://img.webcodegenie.com/img/header/hire/backend.svg?ver=20240318052029" alt="Back-End" height="32" width="32" />
                                  Hire Back-End Developers
                                </div>
                                <div data-tab="tab-8" className="tablinks">
                                  <img className="app-icon" loading="lazy" src="https://img.webcodegenie.com/img/header/hire/frontend.svg?ver=20240318052029" alt="Front-End" height="32" width="32" />
                                  Hire Front-End Developers
                                </div>
                                <div data-tab="tab-9" className="tablinks">
                                  <img className="app-icon" loading="lazy" src="https://img.webcodegenie.com/img/header/hire/fullstack.svg?ver=20240318052029" alt="Full Stack" height="32" width="32" />
                                  Hire Full Stack Developers
                                </div>
                                <div data-tab="tab-10" className="tablinks">
                                  <img className="app-icon" loading="lazy" src="https://img.webcodegenie.com/img/header/hire/testing.svg?ver=20240318052029" alt="Software QA Testers" height="32" width="32" />
                                  Hire Software QA Testers
                                </div>
                                <div data-tab="tab-11" className="tablinks">
                                  <img className="app-icon" loading="lazy" src="https://img.webcodegenie.com/img/header/hire/other.svg?ver=20240318052029" alt="Others" height="32" width="32" />
                                  Others
                                </div>
                              </div>
                              <div className="tabcontent-wrapper">
                                <div id="tab-6" className="tabcontent active">
                                  <ul>
                                    <li><a href="/hire-android-developers"><img src="https://img.webcodegenie.com/img/header/hire/android.svg?ver=20240318052029" alt="Hire Android Developers" />Hire Android Developers</a></li>
                                    <li><a href="/hire-kotlin-developers"><img src="https://img.webcodegenie.com/img/header/hire/kotlin.svg?ver=20240318052029" alt="Hire Kotlin Developers" />Hire Kotlin Developers</a></li>
                                    <li><a href="/hire-ios-developers"><img src="https://img.webcodegenie.com/img/header/hire/ios.svg?ver=20240318052029" alt="Hire iOS Developers" />Hire iOS Developers</a></li>
                                    <li><a href="/hire-swift-developers"><img src="https://img.webcodegenie.com/img/header/hire/swift.svg?ver=20240318052029" alt="Hire Swift Developers" />Hire Swift Developers</a></li>
                                    <li><a href="/hire-react-native-developers"><img src="https://img.webcodegenie.com/img/header/hire/react-native.svg?ver=20240318052029" alt="Hire React Native Developers" />Hire React Native Developers</a></li>
                                    <li><a href="/hire-flutter-developers"><img src="https://img.webcodegenie.com/img/header/hire/flutter.svg?ver=20240318052029" alt="Hire Flutter Developer" />Hire Flutter Developer</a></li>
                                    <li><a href="/hire-ionic-developers"><img src="https://img.webcodegenie.com/img/header/hire/ionic.svg?ver=20240318052029" alt="Hire Ionic Developer" />Hire Ionic Developer</a></li>
                                    <li><a href="/hire-xamarin-developers"><img src="https://img.webcodegenie.com/img/header/hire/xamarin.svg?ver=20240318052029" alt="Hire Xamarin Developer" width="24" />Hire Xamarin Developer</a></li>
                                  </ul>
                                </div>
                                <div id="tab-7" className="tabcontent">
                                  <ul>
                                    <li><a href="/hire-nodejs-developers"><img src="https://img.webcodegenie.com/img/header/hire/nodejs.svg?ver=20240318052029" alt="Hire NodeJS Developers" />Hire NodeJS Developers</a></li>
                                    <li><a href="/hire-php-developers"><img src="https://img.webcodegenie.com/img/header/hire/php.svg?ver=20240318052029" alt="Hire PHP Developers" />Hire PHP Developers</a></li>
                                    <li><a href="/hire-laravel-developers"><img src="https://img.webcodegenie.com/img/header/hire/laravel.svg?ver=20240318052029" alt="Hire Laravel Developers" />Hire Laravel Developers</a></li>
                                    <li><a href="/hire-cakephp-developers"><img src="https://img.webcodegenie.com/img/header/hire/cakephp.svg?ver=20240318052029" alt="Hire CakePHP Developer" />Hire CakePHP Developer</a></li>
                                    <li><a href="/hire-codeigniter-developers"><img src="https://img.webcodegenie.com/img/header/hire/codeigniter.svg?ver=20240318052029" alt="Hire CodeIgniter Developers" />Hire CodeIgniter Developers</a></li>
                                    <li><a href="/hire-javascript-developers"><img src="https://img.webcodegenie.com/img/header/hire/javascript.svg?ver=20240318052029" alt="Hire javaScript Developers" />Hire javaScript Developers</a></li>
                                  </ul>
                                </div>
                                <div id="tab-8" className="tabcontent">
                                  <ul>
                                    <li><a href="/hire-angularjs-developers"><img src="https://img.webcodegenie.com/img/header/hire/angular.svg?ver=20240318052029" alt="Hire AngularJs Developer" />Hire AngularJs Developer</a></li>
                                    <li><a href="/hire-reactjs-developers"><img src="https://img.webcodegenie.com/img/header/hire/reactjs.svg?ver=20240318052029" alt="Hire ReactJs Developer" />Hire ReactJs Developer</a></li>
                                    <li><a href="/hire-vuejs-developers"><img src="https://img.webcodegenie.com/img/header/hire/vuejs.svg?ver=20240318052029" alt="Hire VueJS Developer" />Hire VueJS Developer</a></li>
                                    <li><a href="/hire-html-developers"><img src="https://img.webcodegenie.com/img/header/hire/html.svg?ver=20240318052029" alt="Hire HTML Developers" />Hire HTML Developers</a></li>
                                    <li><a href="/hire-ui-ux-designers"><img src="https://img.webcodegenie.com/img/header/hire/ui-ux.svg?ver=20240318052029" alt="Hire UI UX Designers" />Hire UI UX Designers</a></li>
                                    <li><a href="/hire-bootstrap-developers"><img src="https://img.webcodegenie.com/img/header/hire/bootstrap.svg?ver=20240318052029" alt="Hire Bootstrap Developers" />Hire Bootstrap Developers</a></li>
                                  </ul>
                                </div>
                                <div id="tab-9" className="tabcontent">
                                  <ul>
                                    <li><a href="/hire-mean-stack-developers"><img src="https://img.webcodegenie.com/img/header/hire/mean-stack.svg?ver=20240318052029" alt="Hire MEAN Stack Developers" />Hire MEAN Stack Developers</a></li>
                                    <li><a href="/hire-mern-stack-developers"><img src="https://img.webcodegenie.com/img/header/hire/mern-stack.svg?ver=20240318052029" alt="Hire MERN Stack Developers" />Hire MERN Stack Developers</a></li>
                                  </ul>
                                </div>
                                <div id="tab-10" className="tabcontent">
                                  <ul>
                                    <li><a href="/hire-test-automation-engineers"><img src="https://img.webcodegenie.com/img/header/hire/test-automation.svg?ver=20240318052029" alt="Hire Test Automation Engineers" />Hire Test Automation Engineers</a></li>
                                    <li><a href="/hire-security-testers"><img src="https://img.webcodegenie.com/img/header/hire/security-testers.svg?ver=20240318052029" alt="Hire Security Testers" />Hire Security Testers</a></li>
                                    <li><a href="/hire-manual-testers"><img src="https://img.webcodegenie.com/img/header/hire/manual-tester.svg?ver=20240318052029" alt="Hire Manual Testers" />Hire Manual Testers</a></li>
                                  </ul>
                                </div>
                                <div id="tab-11" className="tabcontent">
                                  <ul>
                                    <li><a href="/hire-woocommerce-developers"><img src="https://img.webcodegenie.com/img/header/hire/woocommerce.svg?ver=20240318052029" alt="Hire Woocommerce Developers" />Hire Woocommerce Developers</a></li>
                                    <li><a href="/hire-vue-storefront-developers"><img src="https://img.webcodegenie.com/img/header/hire/vue-storefront.svg?ver=20240318052029" alt="Hire Vue Storefront Developers" />Hire Vue Storefront Developers</a></li>
                                    <li><a href="/hire-aws-solutions-architect"><img src="https://img.webcodegenie.com/img/header/hire/aws-solutions-architect.svg?ver=20240318052029" alt="Hire AWS Solutions Architect" />Hire AWS Solutions Architect</a></li>
                                    <li><a href="/hire-unity-developers"><img src="https://img.webcodegenie.com/img/header/hire/unity.svg?ver=20240318052029" alt="Hire Unity Developers" />Hire Unity Developers</a></li>
                                    <li><a href="/hire-augmented-reality-developers"><img src="https://img.webcodegenie.com/img/header/hire/augmented-reality.svg?ver=20240318052029" alt="Hire Augmented Reality Developers" />Hire Augmented Reality Developers</a></li>
                                  </ul>
                                </div>
                                <div className="bottom-box">
                                  <div className="bottom-box-thumb">
                                    <img src="https://img.webcodegenie.com/img/technology/technology-bottom.svg?ver=20240318052029" alt="Hire Now" />
                                  </div>
                                  <div className="bottom-box-content">
                                    <h3>Need Dedicated Developers?</h3>
                                    <p>Hire dedicated developers as per project requirement flexibly - hourly, weekly, or monthly basis!</p>
                                    <a href="/hire-dedicated-developers" className="btn">Hire Now</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li> */}
                  {/* <li id="solutionList">
                    <Link to="/solutions" className="menu-title">SOLUTIONS</Link>
                    <div id="solutionsSubMenu" className="solutions dropdown-submenu ">
                      <div className="container">
                        <ul className="main-sub-menu">
                          <li>
                            <Link to="/solutions/industry-specific">
                              <img loading="lazy" src={IndustrySpecificSolutions} alt="Industry Specific Solutions" height="27" width="27" />
                              <div>
                                <h6>Industry-Specific Solutions</h6>
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est blanditiis voluptatem</p>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/solutions/digital-workplace">
                              <img loading="lazy" src={DigitalWorkplaceTransformation} alt="Digital Workplace Transformation" height="27" width="27" />
                              <div>
                                <h6>Digital Workplace Transformation</h6>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione nobis optio</p>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/solutions/data-drive">
                              <img loading="lazy" src={DataDrivenBusinessModel} alt="Data Driven Business Model" height="27" width="27" />
                              <div>
                                <h6>Data-Driven Business Model</h6>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi possimus</p>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/solutions/cloud-scalability">
                              <img loading="lazy" src={CloudScalabilitySolutions} alt="Cloud Scalability" height="27" width="27" />
                              <div>
                                <h6>Cloud Scalability Solutions</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non officiis</p>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li> */}
                  <li id="solutionList">
                    <div className="menu-title">RESOURCES</div>
                    <div id="solutionsSubMenu" className="solutions dropdown-submenu ">
                      <div className="container">
                        <ul className="main-sub-menu">
                          <li>
                            <Link to="/blog">
                              <img loading="lazy" src={Blogs} alt="Blogs" height="27" width="27" />
                              <div>
                                <h6>Our Blogs</h6>
                                <p>Explore the latest trends for the different Technologies with Our Blogs</p>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/case-study">
                              <img loading="lazy" src={DigitalWorkplaceTransformation} alt="Digital Workplace Transformation" height="27" width="27" />
                              <div>
                                <h6>Case Studies</h6>
                                <p>Explore a diverse array of projects where we've tackled challenges, leveraging technologies and strategic insights.</p>
                              </div>
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/solutions/data-drive">
                              <img loading="lazy" src={DataDrivenBusinessModel} alt="Data Driven Business Model" height="27" width="27" />
                              <div>
                                <h6>Data-Driven Business Model</h6>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi possimus</p>
                              </div>
                            </Link>
                          </li> */}
                          {/* <li>
                            <Link to="/solutions/cloud-scalability">
                              <img loading="lazy" src={CloudScalabilitySolutions} alt="Cloud Scalability" height="27" width="27" />
                              <div>
                                <h6>Cloud Scalability Solutions</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non officiis</p>
                              </div>
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li id="company">
                    <div className="menu-title">COMPANY</div>
                    <div id="companySubMenu" className="dropdown-submenu company">
                      <div className="container">
                        <ul className="main-sub-menu">
                          <li>
                            <Link to="/about">
                              <img loading="lazy" src={About} alt="About" height="27" width="27" />
                              <div>
                                <h6>About us</h6>
                                <p>Our collaborative approach ensures a seamless and successful digital journey for our clients.</p>
                              </div>
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/blog">
                              <img loading="lazy" src={Blogs} alt="Blogs" height="27" width="27" />
                              <div>
                                <h6>Our Blogs</h6>
                                <p>Explore the latest trends for the different Technologies with Our Blogs</p>
                              </div>
                            </Link>
                          </li> */}
                          <li>
                            <Link to="/career">
                              <img loading="lazy" src={Career} alt="Career" height="27" width="27" />
                              <div>
                                <h6>Careers</h6>
                                <p>Take your career to the next level and join us on the forefront of digital innovation.</p>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact">
                              <img loading="lazy" src={Contact} alt="Contact" height="27" width="27" />
                              <div>
                                <h6>Contact us</h6>
                                <p>Let's start a conversation about how we can bring your digital vision to life - reach out to us today.</p>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="show-tab">
              <Link to="/">
                {/* <StaticImage placeholder="blurred" src="../../images/logo.png" height={55} width={140} alt="logo" /> */}
                {isHeaderFixed && location === '/' ? (
                  <img className="logo-new" src={logoblack} alt="logo" />
                ) : location === '/' || location === '/services/' || location === '/solutions/' || location === '/technologies/' ? (
                  <img className="logo-new" src={logowhite} alt="logo" />
                ) : (
                  <img className="logo-new" src={logoblack} alt="logo" />
                )}
                {/* <img className="logo-new" src={logoblack} height={55} width={140} alt="logo" /> */}
              </Link>
              <nav>
                <div className="nav-mobile active d-flex" onClick={() => handleClick(1)}>
                  {/* <div id="nav-toggle" className="active" onClick={() => handleClick(1)}> */}
                  <span className="pi pi-bars"></span>
                  {/* </div> */}
                </div>
                <ul className={`nav-list ${isActive >= 1 ? `active-header` : ''}`}>
                  <li onClick={() => setIsActive(0)} className="has-child">
                    <div className={`home-link`}><Link to="/">Home</Link></div>
                    {/* <ul className={`nav-dropdown`}>
                      <li><a href="/services/web-development">Web Development</a></li>
                      <li><a href="/services/mobile-app-development">Mobile App Development</a></li>
                      <li><a href="/services/big-data">Big Data</a></li>
                      <li><a href="/services/cloud-computing">Cloud Computing</a></li>
                      <li><a href="/services/software-testing">Software Testing</a></li>
                      <li><a href="/services/iot-development">IoT</a></li>
                      <li><a href="/services/web-design">Web Design</a></li>
                      <li><a href="/services/digital-marketing">Digital Marketing </a></li>
                    </ul> */}
                  </li>
                  <li className="has-child nested-child">
                    <div className={`main-link ${(isActive === 2 || isActive === 2.1 || isActive === 2.2 || isActive === 2.3 || isActive === 2.4) ? `svg` : ``}`} onClick={() => handleOptionsClick(2)}>Services</div>
                    <ul className={`nav-sub-dropdown ${(isActive === 2 || isActive === 2.1 || isActive === 2.2 || isActive === 2.3 || isActive === 2.4) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link ${isActive === 2.1 ? `svg` : ``}`} onClick={() => handleServicesClick(2.1)}>Strategic Consulting </div>
                        <ul className={`nav-dropdown ${isActive === 2.1 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/services/strategic-consulting/digital-evolution-strategy">Digital Evolution Strategy</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/strategic-consulting/tech-advisory">Tech Advisory</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/strategic-consulting/data-innovation-consulting">Data Innovation Consulting</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/strategic-consulting/next-gen-cloud-solutions">Next-Gen Cloud Solutions</Link></li>
                          {/* <li onClick={() => setIsActive(0)}><Link to="/services/strategic-consulting/insight-driven-analytics">Insight-Driven Analytics</Link></li> */}
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 2 || isActive === 2.1 || isActive === 2.2 || isActive === 2.3 || isActive === 2.4) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link ${isActive === 2.2 ? `svg` : ``}`} onClick={() => handleServicesClick(2.2)}>Operational Excellence </div>
                        <ul className={`nav-dropdown ${isActive === 2.2 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/services/operational-excellence/enterprise-application-services">Enterprise Application Services</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/operational-excellence/cloud-infrastructure-optimization">Cloud Infrastructure Optimization</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/operational-excellence/virtual-support-ecosystem">Virtual Support Ecosystem</Link></li>
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 2 || isActive === 2.1 || isActive === 2.2 || isActive === 2.3 || isActive === 2.4) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link ${isActive === 2.3 ? `svg` : ``}`} onClick={() => handleServicesClick(2.3)}>Migration & Modernization</div>
                        <ul className={`nav-dropdown ${isActive === 2.3 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/services/migration-modernization/legacy-to-cloud-transition">Legacy to Cloud Transition</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/migration-modernization/enterprise-system-upgrades">Enterprise System Upgrades</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/migration-modernization/communication-system-overhaul">Communication System Overhaul</Link></li>
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 2 || isActive === 2.1 || isActive === 2.2 || isActive === 2.3 || isActive === 2.4) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link ${isActive === 2.4 ? `svg` : ``}`} onClick={() => handleServicesClick(2.4)}>AI & Automation Integration</div>
                        <ul className={`nav-dropdown ${isActive === 2.4 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/services/automation-integration/ai-powered-transformations">AI-Powered Transformations</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/automation-integration/intelligent-process-automation">Intelligent Process Automation</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/automation-integration/cognitive-computing-services">Cognitive Computing Services</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/services/automation-integration/microsoft-copilot">Microsoft Copilot</Link></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="has-child nested-child">
                    <div className={`main-link ${(isActive === 3 || isActive === 3.1 || isActive === 3.2 || isActive === 3.3 || isActive === 3.4 || isActive === 3.5 || isActive === 3.6) ? `svg` : ``}`} onClick={() => handleOptionsClick(3)}>Technologies & Platforms</div>

                    <ul className={`nav-sub-dropdown ${(isActive === 3 || isActive === 3.1 || isActive === 3.2 || isActive === 3.3 || isActive === 3.4 || isActive === 3.5 || isActive === 3.6) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link  ${isActive === 3.1 ? `svg` : ``}`} onClick={() => handleTechnologyClick(3.1)}>Microsoft Ecosystem</div>
                        <ul className={`nav-dropdown ${isActive === 3.1 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/microsoft-ecosystem/sharepoint">SharePoint</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/microsoft-ecosystem/office-365">Office 365</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/microsoft-ecosystem/dynamics-365">Dynamics 365</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/microsoft-ecosystem/teams">Teams</Link></li>
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 3 || isActive === 3.1 || isActive === 3.2 || isActive === 3.3 || isActive === 3.4 || isActive === 3.5 || isActive === 3.6) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link  ${isActive === 3.2 ? `svg` : ``}`} onClick={() => handleTechnologyClick(3.2)}>Low Code Innovation </div>
                        <ul className={`nav-dropdown ${isActive === 3.2 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/low-code-innovation/powerapps">PowerApps</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/low-code-innovation/power-automate">Power Automate</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/low-code-innovation/outsystems">OutSystems</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/low-code-innovation/nintex">Nintex</Link></li>
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 3 || isActive === 3.1 || isActive === 3.2 || isActive === 3.3 || isActive === 3.4 || isActive === 3.5 || isActive === 3.6) ? `d-block` : ``}`}>
                      <li className={`sub-link  ${isActive === 3.3 ? `svg` : ``}`} onClick={() => handleTechnologyClick(3.3)}>
                        <div className={`nav-dropdown ${isActive === 3.3 ? `d-block` : ``}`}>Azure Cloud Services </div>
                        <ul className="nav-dropdown">
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/azure-cloud-services/azure-applications">Azure Applications</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/azure-cloud-services/cloud-migrations">Cloud Migrations</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/azure-cloud-services/azure-data-services">Azure Data Services</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/azure-cloud-services/azure-cloud-infra-services">Azure Cloud Infra Services</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/azure-cloud-services/azure-ai-and-cognitive-services">Azure AI and Cognitive Services</Link></li>
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 3 || isActive === 3.1 || isActive === 3.2 || isActive === 3.3 || isActive === 3.4 || isActive === 3.5 || isActive === 3.6) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link  ${isActive === 3.4 ? `svg` : ``}`} onClick={() => handleTechnologyClick(3.4)}>Data Intelligence & AI</div>
                        <ul className={`nav-dropdown ${isActive === 3.4 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/data-intelligence/power-bi">Power BI</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/data-intelligence/azure-data-factory">Azure Data Factory</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/data-intelligence/ai-model-development">AI model development</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/data-intelligence/azure-synapse-analytics-services">Azure Synapse Analytics Services</Link></li>
                        </ul>
                      </li>
                    </ul>
                    <ul className={`nav-sub-dropdown ${(isActive === 3 || isActive === 3.1 || isActive === 3.2 || isActive === 3.3 || isActive === 3.4 || isActive === 3.5 || isActive === 3.6) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link  ${isActive === 3.5 ? `svg` : ``}`} onClick={() => handleTechnologyClick(3.5)}>Modern Development Frameworks</div>
                        <ul className={`nav-dropdown ${isActive === 3.5 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/react-js">React JS</Link></li>
                          {/* <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/angular-js">Angular JS</Link></li> */}
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/spfx">SPFX</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/powerquery">PowerQuery</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/powerfx">PowerFx</Link></li>
                          {/* <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/angular-js">Angular JS</Link></li> */}
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/node-js">Node.js</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/next-js">Next.js</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/net-core">.Net Core</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/gatsby">Gatsby</Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/modern-development/graph-api">Microsoft Graph API</Link></li>
                        </ul>
                      </li>
                    </ul>
                    {/* <ul className={`nav-sub-dropdown ${(isActive === 3 || isActive === 3.1 || isActive === 3.2 || isActive === 3.3 || isActive === 3.4 || isActive === 3.5 || isActive === 3.6) ? `d-block` : ``}`}>
                      <li className="has-sub-child">
                        <div className={`sub-link  ${isActive === 3.6 ? `svg` : ``}`} onClick={() => handleTechnologyClick(3.6)}>Advanced UI/UX Tools</div>
                        <ul className={`nav-dropdown ${isActive === 3.6 ? `d-block` : ``}`}>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/advanced-tools/figma">Figma </Link></li>
                          <li onClick={() => setIsActive(0)}><Link to="/technologies/advanced-tools/adobe-xd">Adobe XD</Link></li>
                        </ul>
                      </li>
                    </ul> */}
                  </li>
                  {/* <li className="has-child">
                    <div className={`main-link ${isActive === 4 ? `svg` : ``}`} onClick={() => handleOptionsClick(4)}>Solutions</div>
                    <ul className={`nav-dropdown ${isActive === 4 ? `d-block` : ``}`}>
                      <li onClick={() => setIsActive(0)}><Link to="/solutions/industry-specific">Industry-Specific Solutions</Link></li>
                      <li onClick={() => setIsActive(0)}><Link to="/solutions/digital-workplace">Digital Workplace Transformation</Link></li>
                      <li onClick={() => setIsActive(0)}><Link to="/solutions/data-drive">Data-Driven Business Model</Link></li>
                      <li onClick={() => setIsActive(0)}><Link to="/solutions/cloud-scalability">Cloud Scalability Solutions</Link></li>
                    </ul>
                  </li> */}
                  <li className="has-child">
                    <div className={`main-link ${isActive === 4 ? `svg` : ``}`} onClick={() => handleOptionsClick(4)}>Resources</div>
                    <ul className={`nav-dropdown ${isActive === 4 ? `d-block` : ``}`}>
                      {/* <li onClick={() => setIsActive(0)}><Link to="/blog">Our Blogs</Link></li> */}
                      <li onClick={() => setIsActive(0)}><Link to="/case-study">Case Studies</Link></li>
                      {/* <li onClick={() => setIsActive(0)}><Link to="/solutions/data-drive">Data-Driven Business Model</Link></li>
                      <li onClick={() => setIsActive(0)}><Link to="/solutions/cloud-scalability">Cloud Scalability Solutions</Link></li> */}
                    </ul>
                  </li>
                  <li className="has-child">
                    <div className={`main-link ${isActive === 5 ? `svg` : ``}`} onClick={() => handleOptionsClick(5)}>Company</div>
                    <ul className={`nav-dropdown ${isActive === 5 ? `d-block` : ``}`}>
                      <li onClick={() => setIsActive(0)}><Link to="/about">About us</Link></li>
                      {/* <li onClick={() => setIsActive(0)}><Link to="/blog">Our Blogs</Link></li> */}
                      <li onClick={() => setIsActive(0)}><Link to="/career">Careers</Link></li>
                      <li onClick={() => setIsActive(0)}><Link to="/contact">Contact us</Link></li>
                    </ul>
                  </li>
                  <li className="d-flex"><Link className="btn" to="/contact">Get In Touch</Link></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header;
